<template>
  <div class="detail">
    <h2>上传出售产品</h2>
    <div class="product_wrapper">
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="产品名称" prop="productName">
          <el-input
            v-model="form.productName"
            placeholder="请输入产品名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品主图" prop="imageUrl">
          <div class="flex">
            <el-upload
              class="avatar-uploader"
              action="/api/app-jycy-disabled/uploadImg"
              :show-file-list="false"
              :on-success="handleAvatarimageurl"
              :on-error="erroupload"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.productImage"
                :src="form.productImage"
                class="img_avatar avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p class="marL20">支持PNG、JPG格式</p>
          </div>
        </el-form-item>
        <el-form-item label="宣传图片">
          <div class="flex">
            <el-upload
              class="avatar-uploader"
              action="/api/app-jycy-disabled/uploadImg"
              list-type="picture-card"
              :file-list="UrlLists"
              :show-file-list="true"
              :limit="6"
              :on-success="handleAvatarSuccess"
              :on-remove="handeleRemove"
              :before-upload="beforeAvatarUpload"
              :on-preview="handlePictureCardPreview"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p class="marL20">支持PNG、JPG格式 大小不高于1M不低于200KB</p>
          </div>
        </el-form-item>
        <el-form-item label="产品规格" prop="productSpec">
          <el-input
            v-model="form.productSpec"
            placeholder="产品的体积、大小、型号。是用来识别物品的编号"
          ></el-input>
        </el-form-item>

        <el-form-item label="产品价格" prop="productPrice">
          <el-input
            v-model="form.productPrice"
            placeholder="请输入产品价格"
            type="number"
          ></el-input>
        </el-form-item>
        <el-form-item label="计量单位" prop="measure">
          <el-input
            v-model="form.unitMeasure"
            placeholder="请输入计量单位"
          ></el-input>
        </el-form-item>

        <el-form-item label="产品简介" prop="productIntroduction">
          <el-input
            v-model="form.productIntro"
            placeholder="请输入产品名简介"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品类别" prop="qydjCpfl1">
          <ThreeLink
            placeholder="请选择产品类别,便于消费者进行查找"
            :codeType="'product_fir'"
            :codeLevel="'2'"
            @setMValue="setScasValue($event, form, ['qydjCpfl1', 'qydjCpfl2'])"
            :defaultValue="[form.qydjCpfl1, form.qydjCpfl2]"
          ></ThreeLink>
        </el-form-item>
        <el-form-item label="参数属性" prop="productExplain">
          <el-input
            type="textarea"
            v-model="form.paramAttr"
            placeholder="商品性质、性能、品质等一系列的指标"
          ></el-input>
        </el-form-item>
        <el-form-item label="特别说明">
          <el-input
            type="textarea"
            v-model="form.specialNote"
            placeholder="指该商品需要特别注意的地方"
          ></el-input>
        </el-form-item>
        <el-form-item label="售后保障" prop="specification">
          <el-input
            type="textarea"
            v-model="form.afterSale"
            placeholder="您可以为消费者提供的一系列售后服务"
          ></el-input>
        </el-form-item>
        <el-form-item label="商品详情" prop="description">
          <Editor
            placeholder="请输入产品详细介绍"
            @html="text"
            :testcontent="form.productDetails"
          ></Editor>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit('form')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Editor from "components/public/editor2";
import ThreeLink from "components/selectComponents/ThreeLink";
export default {
  inject: ["reload"],
  components: {
    Editor,
    ThreeLink,
  },
  data() {
    return {
      form: {
        specialNote: "",
        productDetails: "",
        productName: "",
        paramAttr: "",
        productIntro: "",
        afterSale: "",
        unitMeasure: "",
        productImage: "",
        promotionalGra: '',
        productPrice: "",
        productSpec: "",
        qydjCpfl1: "",
        qydjCpfl2: "",
      },
      rules: {
        supplement: [
          {
            required: true,
            message: "请输入该产品的特别注意事项",
            trigger: "blur",
          },
        ],
        qydjCpfl1: [
          {
            required: true,
            message: "请输入该产品类别",
            trigger: "change",
          },
        ],
        productSpec: [
          {
            required: true,
            message: "请输入产品规格哦",
            trigger: "blur",
          },
        ],
        productDetails: [
          {
            required: true,
            message: "请输入产品描述",
            trigger: "blur",
          },
        ],
        productName: [
          {
            required: true,
            message: "请输入产品名称",
            trigger: "blur",
          },
        ],
        paramAttr: [
          {
            required: true,
            message: "请输入参数属性",
            trigger: "blur",
          },
        ],
        productIntro: [
          {
            required: true,
            message: "请输入产品简介",
            trigger: "blur",
          },
        ],
        afterSale: [
          {
            required: true,
            message: "请输入售后保障",
            trigger: "blur",
          },
        ],
        unitMeasure: [
          {
            required: true,
            message: "请输入产品的计量单位，便于售卖",
            trigger: "blur",
          },
        ],
        productPrice: [
          {
            required: true,
            message: "请输入产品价格",
            trigger: "blur",
          },
        ],
        imgs: [],
      },
      UrlLists: [],
      imgsUrl: {},
      dialogImageUrl: "",
      imgUrl: [],
    };
  },
  methods: {
    async onSubmit(form) {
      this.form.promotionalGra = JSON.stringify(this.imgUrl);
      this.$refs[form].validate(async (valid) => {
        if (valid) {
          let res = await this.$axios.post('/api/app-jycy-gansulz/product/save',this.form);
          if (res.data.success) {
            this.$message.success(res.data.msg);
            this.form = {};
            this.$router.push("/center/worMange/");
          } else {
            this.$message.warning(res.data.msg);
          }
          this.reload();
        }
      });
    },
    erroupload() {
      this.$message({
        message: "图片上传失败，请重新上传！！",
        center: true,
      });
    },
    setScasValue(event, form, keys) {
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    beforeAvatarUpload(file) {
      let type = ["image/jpeg", "image/png"];
      const isImage = type.includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message.error("上传图片格式不正确");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isImage && isLt2M;
    },
    // 上传主图
    handleAvatarimageurl(res, file) {
      this.$message.success("上传图片成功");
      this.form.productImage = res.msg;
    },
    handeleRemove(file, fileList) {
      this.imgUrl = this.imgUrl.filter((x) => x !== file.url);;
    },
    // 上传图片墙
    handleAvatarSuccess(res, file) {
      this.$message.success("上传图片成功");
      this.imgUrl.push(res.msg);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
    },

    beforeRemove(file, imgs) {
      return this.$confirm("是否删除该图片?");
    },
    text(val) {
      this.form.productDetails = val;
    },
    async showProduct() {
      let res = await this.$axios.get('/api/app-jycy-gansulz/product/details?id='+ this.$route.query.id );
      if (res.data.success) {
        this.form = res.data.data;
        let sss = JSON.parse(this.form.promotionalGra);
        for (let a in sss) {
          this.imgUrl.push(sss[a]);
          this.UrlLists.push({ url: sss[a] });
        }
      }
    },
  },
  created() {
    if (this.$route.query.id) {
      this.showProduct();
    }
  },
};
</script>
<style lang="less" scoped>
@import "~assets/css/public";
/deep/ .el-cascader {
  width: 100%;
}
h2 {
  background-color: #ffffff;
  padding-left: 24px;
  color: @00;
  height: 48px;
  line-height: 48px;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
}
.product_wrapper {
  background-color: #ffffff;
  padding: 36px;

}
  .el-form {
    width: 65%;
    margin: auto;
    // rgba(243, 247, 250, 1)
    /deep/ .el-textarea__inner,
    /deep/ .el-input__inner,
    /deep/ .el-upload {
      background-color: #f3f7fa;
    }
  }
 /deep/  .editor[data-v-29a0dd4f],.editor[data-v-fa151df6] {
    margin-bottom: 0px;
    height: 100%;
    .ql-editor {
      min-height: 200px !important;
    }
  }
  .el-button {
    width: 100%;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }
/deep/ .avatar-uploader-icon[data-v-5e54234f],
/deep/ .el-upload--picture-card {
  width: 88px !important;
  height: 88px !important;
  line-height: 88px !important;
  border: none;
}
/deep/ .ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 220px;
}
P {
  color: #7c7d7d;
  font-size: 12px;
  font-family: PingFang SC;
}
.el-form-item {
  width: 100%;
}
/deep/ .el-textarea__inner,
/deep/ .el-input__inner /deep/ .ql-editor {
  color: #7c7d7d;
  font-size: 12px;
  font-family: PingFang SC;
}
/deep/ .el-input__inner::placeholder {
  color: #bbb;
  font-size: 12px;
  font-family: PingFang SC;
}
</style>
