<template>
    <div class='editor'>
        <div class="quill-editor text_L eagle-a11y-uncut"
             :content="testcontent"
             :disabled="disabled"
             @change="onEditorChange($event)"
             @blur="onEditorBlur($event)"
             @focus="onEditorFocus($event)"
             @ready="onEditorReady($event)"
             v-quill:myQuillEditor="editorOption">
        </div>
        <!-- v-model="testcontent" -->
    </div>
</template>

<script>

    export default {
        name: 'editor2',
        props: ["maxlength", "placeholder", "testcontent", "disabled"],
        data() {
            return {
                content: '',//富文本中数据
                editorOption: {
                    // 富文本中的一些属性
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],        //加粗，斜体，下划线，删除线
                            ['blockquote', 'code-block'],         //引用，代码块
                            [{'header': 1}, {'header': 2}],               // 标题，键值对的形式；1、2表示字体大小
                            [{'list': 'ordered'}, {'list': 'bullet'}],       //列表
                            [{'script': 'sub'}, {'script': 'super'}],      // 上下标
                            [{'indent': '-1'}, {'indent': '+1'}],          // 缩进
                            [{'direction': 'rtl'}],                         // 文本方向
                            ['link', 'image'],                              //图片 和超链接
                            [{'size': ['small', false, 'large', 'huge']}],  // 字体大小
                            [{'header': [1, 2, 3, 4, 5, 6, false]}],         //几级标题

                            [{'color': []}, {'background': []}],          // 字体颜色，字体背景颜色
                            [{'font': []}],         //字体
                            [{'align': []}],        //对齐方式
                            ['clean'],        //清除字体样式
                        ]
                    },
                    theme: 'snow',
                    placeholder: this.placeholder,
                    // maxlength:'150文字',
                }
            }
        },
        mounted() {
        },
        methods: {
            onEditorChange({editor, html, text}) {//内容改变事件
                // console.log( html)
                this.content = html;
                this.$emit('html', this.content);
            }
            ,
            onEditorBlur(editor) {//失去焦点事件
                // console.log('editor blur!', editor)
            }
            ,
            onEditorFocus(editor) {//获得焦点事件
                console.log('editor focus!', editor)
            }
            ,
            onEditorReady(editor) {//准备编辑器
                // console.log(editor)
            }
            ,
            save() {//提交
                //....
            }
        },
        watch: {
            'content': function (val) {
                // console.log(val)
            }
        }
    }
</script>
<style scoped lang="less">
    .editor {
        margin-bottom: 30px;
      .quill-editor{
          height:200px;
      }
    }
</style>
